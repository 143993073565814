import React from 'react'
import joseph0 from './tragicjoe/day1/joseph0.png'
import joseph1 from './tragicjoe/day1/joseph1.png'
import joseph2 from './tragicjoe/day1/joseph2.png'
import joseph3 from './tragicjoe/day1/joseph3.png'
import joseph4 from './tragicjoe/day1/joseph4.png'
import joseph10 from './tragicjoe/day1/joseph10.png'
import joseph11 from './tragicjoe/day1/joseph11.png'
import joseph12 from './tragicjoe/day1/joseph12.png'
import joseph13 from './tragicjoe/day1/joseph13.png'
import joseph17 from './tragicjoe/day1/joseph17.png'
import joseph18 from './tragicjoe/day1/joseph18.png'
import joseph19 from './tragicjoe/day1/joseph19.png'
import joseph20 from './tragicjoe/day1/joseph20.png'
import joseph21 from './tragicjoe/day1/joseph21.png'
import joseph22 from './tragicjoe/day1/joseph22.png'
import joseph23 from './tragicjoe/day1/joseph23.png'
import joseph24 from './tragicjoe/day1/joseph24.png'
import joseph25 from './tragicjoe/day1/joseph25.png'
import joseph26 from './tragicjoe/day1/joseph26.png'
import joseph27 from './tragicjoe/day1/joseph27.png'
import joseph28 from './tragicjoe/day1/joseph28.png'
import joseph29 from './tragicjoe/day1/joseph29.png'
import joseph30 from './tragicjoe/day1/joseph30.png'
import joseph36 from './tragicjoe/day2/joseph36.png'
import joseph37 from './tragicjoe/day2/joseph37.png'
import joseph38 from './tragicjoe/day2/joseph38.png'
import joseph39 from './tragicjoe/day2/joseph39.png'
import joseph40 from './tragicjoe/day2/joseph40.png'
import joseph41 from './tragicjoe/day2/joseph41.png'
import joseph42 from './tragicjoe/day2/joseph42.png'
import joseph43 from './tragicjoe/day2/joseph43.png'
import joseph44 from './tragicjoe/day2/joseph44.png'
import joseph45 from './tragicjoe/day2/joseph45.png'
import joseph46 from './tragicjoe/day2/joseph46.png'
import joseph47 from './tragicjoe/day2/joseph47.png'
import joseph48 from './tragicjoe/day2/joseph48.png'
import joseph49 from './tragicjoe/day2/joseph49.png'
import joseph50 from './tragicjoe/day2/joseph50.png'
import joseph51 from './tragicjoe/day3/joseph51.png'
import joseph52 from './tragicjoe/day3/joseph52.png'
import joseph53 from './tragicjoe/day3/joseph53.png'
import joseph54 from './tragicjoe/day3/joseph54.png'
import joseph55 from './tragicjoe/day3/joseph55.png'
import joseph56 from './tragicjoe/day3/joseph56.png'
import joseph57 from './tragicjoe/day3/joseph57.png'
import joseph58 from './tragicjoe/day3/joseph58.png'
import joseph59 from './tragicjoe/day3/joseph59.png'
import joseph60 from './tragicjoe/day3/joseph60.png'
import joseph61 from './tragicjoe/day3/joseph61.png'
import joseph62 from './tragicjoe/day3/joseph62.png'
import joseph63 from './tragicjoe/day3/joseph63.png'
import joseph64 from './tragicjoe/day4/joseph64.png'
import joseph65 from './tragicjoe/day4/joseph65.png'
import joseph66 from './tragicjoe/day4/joseph66.png'
import joseph67 from './tragicjoe/day4/joseph67.png'
import joseph68 from './tragicjoe/day4/joseph68.png'
import joseph69 from './tragicjoe/day5/joseph69.png'
import joseph70 from './tragicjoe/day5/joseph70.png'
import joseph71 from './tragicjoe/day5/joseph71.png'
import joseph72 from './tragicjoe/day5/joseph72.png'
import joseph73 from './tragicjoe/day5/joseph73.png'
import joseph74 from './tragicjoe/day5/joseph74.png'
import joseph75 from './tragicjoe/day5/joseph75.png'
import joseph76 from './tragicjoe/day5/joseph76.png'
import joseph77 from './tragicjoe/epilogue/joseph77.png'
import joseph78 from './tragicjoe/epilogue/joseph78.png'
import joseph79 from './tragicjoe/epilogue/joseph79.png'
import joseph80 from './tragicjoe/epilogue/joseph80.png'
import joseph81 from './tragicjoe/epilogue/joseph81.png'
import josephend from './tragicjoe/epilogue/josephend.png'

const Joseph = () => {
  return (
    <div>
      <i><p />Everything in life is storytelling.
How we think the world works and who we are is a story we tell ourselves because our broken pattern recognizing brains
can't handle genuine chaos.
<p />"Investment Theses" are stories. Art is a story. History is a story. All stories are true, and all stories are lies.</i>
      <p />This is a little bit of our shared history. This is BASED history. This is:

      <h1> Above Average Joe: (or The Unending Tragedy of Being 105 IQ)</h1>
      <h2>Chapter 1: HOPE - August 21, 2020</h2>
      <img alt="" width="500px" src={joseph0} />
      <p />Joseph enters our little based world. We are not ready for the TRUTH that he will bring. He is messianic.
      <p /><img alt="" width="500px" src={joseph1} />
      <p /><img alt="" width="500px" src={joseph2} />
      <p /><img alt="" width="500px" src={joseph3} />
      <p />Joseph is indeed above average. He comes in guns blazing with high confidence predictions.
      <p /><img alt="" width="500px" src={joseph4} />
      <p />He also uses big words like "rehypothecation". This word, what does it mean? Nobody knows, not even Joe. Especially not us 70 IQs.
      <p /><img alt="" width="500px" src={joseph10} />
      <p /><img alt="" width="500px" src={joseph11} />
      <p /><img alt="" width="500px" src={joseph12} />
      <p /><img alt="" width="500px" src={joseph13} />
      <p />Joseph is a whale hunter and truth teller. He's teaching us all how to beat the whales at their own game. His wisdom is undeniable. He sounds like he knows the future.
      <p /><img alt="" width="500px" src={joseph17} />
      <p /> Joseph also has a wife, whom he pleased greatly.
      <p /><img alt="" width="500px" src={joseph18} />
      <p /><img alt="" width="500px" src={joseph19} />
      <p /><img alt="" width="500px" src={joseph20} />
      <p /><img alt="" width="500px" src={joseph21} />
      <p /><img alt="" width="500px" src={joseph22} />
      <p /><img alt="" width="500px" src={joseph23} />
      <p /><img alt="" width="500px" src={joseph24} />
      <p /><img alt="" width="500px" src={joseph25} />
      <p /><img alt="" width="500px" src={joseph26} />
      <p /><img alt="" width="500px" src={joseph27} />
      <p /><img alt="" width="500px" src={joseph28} />
      <p /><img alt="" width="500px" src={joseph29} />
      <p /> This story is one of hope. It is the age old story of a plucky rebel rallying the people and outwitting the oppressors.

      <p />He is not only a gamechanger... He is a game breaker. The system is no match for him.

      <p />He has outsmarted everyone by giving power back to the people. He has many ardent followers.

      <p /><img alt="" width="500px" src={joseph30} />
      <p /> He also has a very large penis, as all male protaganists in epic tales do.

      <h2>Chapter 2: CONVICTION - August 22, 2020</h2>

      <p /><img alt="" width="500px" src={joseph36} />
      <p />Joseph is fond of bold predictions.
      <p /><img alt="" width="500px" src={joseph37} />
      <p /><img alt="" width="500px" src={joseph38} />
      <p /><img alt="" width="500px" src={joseph39} />
      <p /> Joseph comes under attack, but our hero is no coward. He will not back down. He stands by his convictions as any hero would. These attacks are baseless and spiteful.
      <p />Fear not. Our hero will be vindicated by the Halving.
      <p /><img alt="" width="500px" src={joseph40} />
      <p /><img alt="" width="500px" src={joseph41} />
      <p /><img alt="" width="500px" src={joseph42} />
      <p />The system stands against him. Hordes of undead seek to damage his credibility, and alas, he is forced backwards into a defensive posture. He bends... but does not break.
      <p />Despite troubles with differentiating probability and game theory, he is undaunted. After all, he may not be a mathmetician, but he is a knower of people.
      <p />He knows this game, and how his foes will act. And so he knows the correct riposte.
      <p /><img alt="" width="500px" src={joseph43} />
      <p /><img alt="" width="500px" src={joseph44} />
      <p />His core conviction is still the same. He will not waver from this noblest of paths.
      <p /><img alt="" width="500px" src={joseph45} />
      <p /><img alt="" width="500px" src={joseph46} />
      <p /><img alt="" width="500px" src={joseph47} />
      <p /><img alt="" width="500px" src={joseph48} />
      <p /><img alt="" width="500px" src={joseph49} />
      <p /><img alt="" width="500px" src={joseph50} />
      <p />When the enemy gains ground, you must strike twice as hard.
      <p />Joseph puts his reputation on the line. these predictions may seem outlandish to some, but that is just because these cowards know nothing of what courage is.
      <p />He pities those who don't have the mettle to follow through. Those who aren't willing to fight for what they believe.
      <p />Those poor souls and their weak, shaking hands. They will never know the taste of victory, for they lack the gumption to be truly great.

      <h3>Chapter 3: DOUBT - August 23, 2020</h3>
      <p /><img alt="" width="500px" src={joseph51} />
      <p /><img alt="" width="500px" src={joseph52} />

      <p />The crowd has turned against our hero. He has made one too many missteps, and the sentiment has changed.
      <p />He remains undeterred. Also, he learned the word straddle, and bravely used it in a sentence. Nevermind that his usage was incorrect, its the conviction that matters.
      <p /><img alt="" width="500px" src={joseph53} />
      <p /><img alt="" width="500px" src={joseph54} />
      <p />Joseph will NOT YIELD (pun not intended). He draws his line in the sand, plants his feet, and dares the universe to knock him down.
      <p /><img alt="" width="500px" src={joseph55} />
      <p />The attacks come from all sides. From up above...
      <p /><img alt="" width="500px" src={joseph56} />
      <p /><img alt="" width="500px" src={joseph57} />
      <p /><img alt="" width="500px" src={joseph58} />
      <p /><img alt="" width="500px" src={joseph59} />
      <p />And from the crazed mob below. His credibility is being questioned, but all great men face their fair share of slander.
      <p />He <b>believes</b>, and ultimately that's what counts.
      <p /><img alt="" width="500px" src={joseph60} />
      <p />The powers that be thwart him at every turn.
      <p /><img alt="" width="500px" src={joseph61} />
      <p /><img alt="" width="500px" src={joseph62} />
      <p /> Perhaps the challenge is too great. Our hero is weary. Even those who he thought allies turn against him.
      <p /><img alt="" width="500px" src={joseph63} />
      <p /> Joseph fights, but his burdens are heavy... Perhaps too heavy for any one man.

      <h3>Chapter 4: DESPAIR - August 24, 2020</h3>
      <p />His spirit has been broken. This world is far too cruel.

      <p />I will let our fallen hero speak his own words...

      <p /><img alt="" width="500px" src={joseph64} />
      <p /><img alt="" width="500px" src={joseph65} />
      <p /><img alt="" width="500px" src={joseph66} />
      <p /><img alt="" width="500px" src={joseph67} />
      <p /><img alt="" width="500px" src={joseph68} />
      <p />His college education has not prepared him for the harsh reality of this BASED game.

      <h3>Chapter 5: DESPAIR - August 25, 2020</h3>

      <p /><img alt="" width="500px" src={joseph69} />
      <p />For those keeping track... the above is the 69th image in this epic saga.
      <p />It is also when Joseph concedes. Perhaps blind conviction is not enough.
      <p />As he says... The music has stopped. It's a game of musical chairs.
      <p />And he has pulled his own chair out from under himself.
      <p /><img alt="" width="500px" src={joseph70} />
      <p />And even as he suffers these wounds, he is etched into the fabric of our BASED history.
      <p /><img alt="" width="500px" src={joseph71} />
      <p /><img alt="" width="500px" src={joseph72} />
      <p /><img alt="" width="500px" src={joseph73} />
      <p />He is bitter... and he wishes he had not played such a dangerous game.
      <p /><img alt="" width="500px" src={joseph74} />
      <p /><img alt="" width="500px" src={joseph75} />
      <p /><img alt="" width="500px" src={joseph76} />
      <p />He has lost the game, and so obviously the game is over.
      <p />To survive, we tell stories about ourselves... to ourselves.
      <p />It is game over for Joseph... so the game is over for you too, reader.

      <h3>EPILOGUE </h3>
      <p /><img alt="" width="500px" src={joseph77} />
      <p /><img alt="" width="500px" src={joseph78} />
      <p />Perhaps our hero was no hero at all.
      <p />Merely a slightly above average Joe who managed to lose even in the easiest of games (YFI).
      <p />A tragic figure. This was not an epic. It was a fable.
      <p /><img alt="" width="500px" src={joseph79} />
      <p /><img alt="" width="500px" src={joseph80} />
      <p />But life moves on. Those who have high time preference have things to do after all.
      <p />Wives need to be pleased and (expensive) paint needs to be purchased.
      <p /><img alt="" width="500px" src={joseph81} />
      <p />He writes the game off as a scam. After all, he lost. With a IQ of 105, how could he lose at anything?
      <p />The game was rigged from the start.
      <p /><img alt="" width="500px" src={josephend} />

    </div>
  )
}

export default Joseph;